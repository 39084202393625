import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import {
  LoadingOutlined,
  EditOutlined,
  PlusOutlined,
  WarningOutlined,
  FundOutlined,
  DashboardOutlined,
  QuestionCircleOutlined,
  BarChartOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import styled from "styled-components";

import { useAccess } from "../../UserAccessContext";
import { AppContent, PageContent } from "../../Router";
import SlimFooter from "../../Components/Footer/SlimFooter";

import useKnowledgeBaseUrl from "./hooks/useKnowledgeBaseUrl";
import useLogicMonitorUrl from "./hooks/useLogicMonitorUrl";
import useLexiconUrl from "./hooks/useLexiconUrl";
import useReportsUrl from "./hooks/useReportsUrl";

const HomeContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10vh 0;
  align-items: center;
  width: 85vw;
  max-width: 1700px;
`;

const Headline = styled.h1`
  font-size: 2.5rem;
  font-weight: 200;
  letter-spacing: 0.0425em;
  text-align: center;
`;

const CardWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  flex-wrap: wrap;
  margin-top: 10vh;
  width: 80%;
`;

const LoadingIcon = styled(LoadingOutlined)`
  font-size: 60px;
  margin-top: calc(10vh + 60px);
  color: lightgrey;
`;

const Card = styled.div`
  width: 200px;
  padding-bottom: 3rem;
  margin: auto;
  height: 300px;

  a:hover {
    text-decoration: none;
  }
`;

const CardText = styled.p`
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-size: 20px;
  font-weight: 200;
  letter-spacing: 0.0625em;

  ${Card} :hover & {
    transition: font-size 0.5s;
    font-size: 21px;
  }

  ${Card} :not(:hover) & {
    transition: font-size 0.5s;
    font-size: 20px;
  }
`;

const CardIcon = `
  font-size: 60px;
  padding: 50px 0px;
  color: lightgrey;
  display: flex;
  justify-content: center;

  transform: scale(1);

  ${Card} :hover & {
    transition: color 0.5s, transform 0.5s;
    color: #0B2962;
    transform: scale(1.1);
  }
  ${Card} :not(:hover) & {
    transition: color 0.5s, transform 0.5s;
    color: lightgrey;

    transform: scale(1);
  }
`;

const TicketsCard = styled(EditOutlined)`
  ${CardIcon}
`;
const NewTicketCard = styled(PlusOutlined)`
  ${CardIcon}
`;
const DisruptionsCard = styled(WarningOutlined)`
  ${CardIcon}
`;
const LexiconCard = styled(FundOutlined)`
  ${CardIcon}
`;
const LogicMonitorCard = styled(DashboardOutlined)`
  ${CardIcon}
`;
const KnowledgeBaseCard = styled(QuestionCircleOutlined)`
  ${CardIcon}
`;
const ReportsCard = styled(BarChartOutlined)`
  ${CardIcon}
`;
const InvoicesCard = styled(CopyOutlined)`
  ${CardIcon}
`;

const Home = () => {
  const {
    isInvoiceReader,
    isInvoiceReaderLoading,
    isLogicMonitorUser,
    isLogicMonitorUserLoading,
  } = useAccess();

  const { logicMonitorUrl, logicMonitorLoading } = useLogicMonitorUrl();
  const { lexiconUrl, lexiconLoading } = useLexiconUrl();
  const { url: knowledgeBaseUrl, loading: knowledgeBaseLoading } =
    useKnowledgeBaseUrl();

  const { url: reportsUrl, loading: reportsLoading } = useReportsUrl();

  return (
    <>
      <AppContent>
        <PageContent center>
          <HomeContent>
            <Headline>
              <FormattedMessage id="Home.authenticated.welcome" />
            </Headline>
            {knowledgeBaseLoading ||
              reportsLoading ||
              logicMonitorLoading ||
              lexiconLoading ||
              isInvoiceReaderLoading ||
              isLogicMonitorUserLoading ? (
              <LoadingIcon spin />
            ) : (
              <CardWrapper>
                <Card>
                  <Link to="/tickets">
                    <TicketsCard />
                    <CardText>
                      <FormattedMessage id="NavBar.tickets" />
                    </CardText>
                  </Link>
                </Card>
                <Card>
                  <Link to="/new_ticket">
                    <NewTicketCard />
                    <CardText>
                      <FormattedMessage id="NavBar.new_ticket" />
                    </CardText>
                  </Link>
                </Card>
                <Card>
                  <Link to="/disruptions">
                    <DisruptionsCard />
                    <CardText>
                      <FormattedMessage id="NavBar.disruptions" />
                    </CardText>
                  </Link>
                </Card>
                {lexiconUrl ? (
                  <Card>
                    <a
                      href={lexiconUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LexiconCard />
                      <CardText>
                        <FormattedMessage id="Home.lexicon" />
                      </CardText>
                    </a>
                  </Card>
                ) : null}
                {isLogicMonitorUser && logicMonitorUrl ? (
                  <Card>
                    <a
                      href={logicMonitorUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LogicMonitorCard />
                      <CardText>
                        <FormattedMessage id="Home.operating_status" />
                      </CardText>
                    </a>
                  </Card>
                ) : null}
                {knowledgeBaseUrl !== null ? (
                  <Card>
                    <a
                      href={knowledgeBaseUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <KnowledgeBaseCard />
                      <CardText>
                        <FormattedMessage id="Home.knowledge_base" />
                      </CardText>
                    </a>
                  </Card>
                ) : null}
                {reportsUrl !== null ? (
                  <Card>
                    <a
                      href={reportsUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ReportsCard />
                      <CardText>
                        <FormattedMessage id="Home.reports" />
                      </CardText>
                    </a>
                  </Card>
                ) : null}
                {isInvoiceReader ? (
                  <Card>
                    <Link to="invoices">
                      <InvoicesCard />
                      <CardText>
                        <FormattedMessage id="NavBar.invoices" />
                      </CardText>
                    </Link>
                  </Card>
                ) : null}
              </CardWrapper>
            )}
          </HomeContent>
        </PageContent>
      </AppContent>
      <SlimFooter />
    </>
  );
};

export default Home;
